import {cx} from 'class-variance-authority';
import {useLoaderData} from '@remix-run/react';
import {useState, useRef} from 'react';

import AutoResponsiveImage from '@/components/base/elements/AutoResponsiveImage/AutoResponsiveImage';
import {useSiteData} from '@/components/shared/Page/SiteData';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import {useIntlAcquisitionIncentive} from '@/hooks/experiments/useIntlAcquisitionIncentive';
import {useSecondaryCTAonIntlBlogHeader} from '@/hooks/experiments/useSecondaryCTAonIntlBlogHeader';
import {useTailoredMessagingTopThreeCTAs} from '@/hooks/experiments/useTailoredMessagingTopThreeCTAs';
import {useBlogArticleWhatIsShopifyVideoTest} from '@/hooks/experiments/useBlogArticleWhatIsShopifyVideoTest';
import {
  getPageCluster,
  getShowLightButtonValue,
  getBlogArticleWhatIsShopifyVideoTest,
} from '@/components/blog/utils';
import Button from '@/components/base/elements/Button/Button';
import SvgPlay from '@/components/base/elements/Icon/icons/Play';
import {useBlogDocumentDownloadCtaExp} from '@/hooks/experiments/useBlogDocumentDownloadCtaExp';

import {ArticleVideoModal} from './ArticleVideoModal/ArticleVideoModal';
import {ArticleModalButton} from './ArticleVideoModal/ArticleModalButton';

const SECONDARY_CTA_ON_E4_BLOG_LOCALES = ['fr', 'it', 'de', 'es'];

export default function BlogArticleHeader() {
  const {
    settings: {topSectionImages, downloadPDFExperiment},
    fileRoutePath,
    pageCluster,
  } = useLoaderData<any>() ?? {};
  const {site} = useSiteData();
  const {t} = useSharedTranslations(`pages${fileRoutePath}/content`);
  const {t: tCTAs} = useSharedTranslations(`pages/blog/$article/ctas`);
  const showLearnMoreBtnJpHeader = site?.locale === 'ja';
  const showLearnMoreBtnE4Header = SECONDARY_CTA_ON_E4_BLOG_LOCALES?.includes(
    site?.locale,
  );
  const showLearnMoreBtnIntlHeader = useSecondaryCTAonIntlBlogHeader();
  const isDownloadPDFFile = useBlogDocumentDownloadCtaExp();
  const hasTailoredMessaging = useTailoredMessagingTopThreeCTAs();
  const showWhatIsShopifyExpValue = useBlogArticleWhatIsShopifyVideoTest();
  const tailoredCTAMessages =
    hasTailoredMessaging &&
    pageCluster &&
    getPageCluster(tCTAs(`hero.${hasTailoredMessaging}.clusters`), pageCluster);

  const {heading, subhead} = tailoredCTAMessages || t('hero');

  const {active, learnMore, creditAmount} = useIntlAcquisitionIncentive();

  const button = t('hero.button');
  let lightButton = t('hero.lightButton');
  if (showLearnMoreBtnJpHeader) {
    lightButton = t(`hero.treatment`);
  } else if (showLearnMoreBtnE4Header) {
    lightButton = t(`hero.treatment`);
  } else if (showLearnMoreBtnIntlHeader) {
    lightButton = t(`hero.${showLearnMoreBtnIntlHeader}`);
  }
  const [image1, image2, image3] = topSectionImages;
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const modalVideoButtonRef = useRef<HTMLButtonElement>(null);
  const toggleVideoModal = () => {
    setIsVideoModalOpen(!isVideoModalOpen);
  };

  const {
    showWhatIsShopifyExternalVideoButton,
    showWhatIsShopifyModalVideoButton,
  } = getBlogArticleWhatIsShopifyVideoTest(
    site.locale,
    showWhatIsShopifyExpValue,
  );

  const showLightButton = getShowLightButtonValue(
    site.locale.startsWith('en'),
    showLearnMoreBtnJpHeader,
    showLearnMoreBtnE4Header,
    !!showLearnMoreBtnIntlHeader,
    showWhatIsShopifyExternalVideoButton,
  );

  if (showWhatIsShopifyExternalVideoButton) {
    lightButton = {
      text: (
        <span className="flex items-center gap-x-2">
          <SvgPlay width={11} height={11} />
          {t('hero.whatIsShopifyVideoExp.text')}
        </span>
      ),
      href: t('hero.whatIsShopifyVideoExp.href'),
      external: true,
    };
  }

  if (isDownloadPDFFile === 'treatment') {
    lightButton = {
      text: t('articleNavigation.downloadPDFExperiment.text'),
      href: downloadPDFExperiment?.url,
      external: true,
    };
  }

  return (
    <BlogConversion
      heading={heading}
      subhead={
        active
          ? t(`hero.acquisitionSubhead`, {
              learnMore: learnMore,
              creditAmount: creditAmount,
            })
          : subhead
      }
      headingAs="p"
      buttonText={button.text}
      buttonHref={button.href}
      buttonSize="small"
      lightButton={showLightButton ? lightButton : null}
      showButtons={!showWhatIsShopifyModalVideoButton}
      сomponentName="blog-header-banner"
    >
      {/* 1005 1288 */}
      {Boolean(image1?.src) && (
        <AutoResponsiveImage
          src={image1.src}
          className={cx(
            'absolute rounded-[15px] z-10',
            'w-[90px] tablet-xl:w-[136px] desktop:w-[175px]',
            'left-[-26px] tablet-xl:left-0 bottom-[-20px] tablet-xl:bottom-[-60px]',
          )}
        />
      )}
      {Boolean(image2?.src) && (
        <div
          className={cx(
            'absolute rounded-[15px] overflow-hidden z-10',
            'w-[90px] tablet-xl:w-[136px] desktop:w-[175px]',
            'h-[85px] tablet-xl:h-[140px] desktop:h-[180px]',
            'right-0 tablet-xl:right-[-20px] desktop:right-[20px] top-[-25px] tablet-xl:top-[-30px] desktop:top-[-40px]',
          )}
        >
          <AutoResponsiveImage src={image2.src} />
        </div>
      )}
      {Boolean(image3?.src) && (
        <div>
          <AutoResponsiveImage
            src={image3.src}
            className={cx(
              'absolute rounded-[15px] z-10',
              'w-[90px] tablet-xl:w-[136px] desktop:w-[175px]',
              'bottom-[20px] tablet-xl:bottom-[-50px] desktop:bottom-[-70px] right-[-50px] tablet-xl:right-[-70px] desktop:right-[-100px]',
            )}
          />
        </div>
      )}
      {showWhatIsShopifyModalVideoButton && (
        <div className="flex flex-col sm:flex-row items-center justify-center gap-2">
          <Button href={button.href} size="small">
            {button.text}
          </Button>

          <ArticleModalButton
            onClick={() => setIsVideoModalOpen(true)}
            ref={modalVideoButtonRef}
            text={t('hero.whatIsShopifyVideoExp.text')}
          />
        </div>
      )}
      <ArticleVideoModal
        isVideoModalOpen={isVideoModalOpen}
        toggleVideoModal={toggleVideoModal}
        modalVideoButtonRef={modalVideoButtonRef}
        embedVideoId={t('hero.whatIsShopifyVideoExp.embedVideoId')}
      />
    </BlogConversion>
  );
}
